import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import HomeAfterLogin from './HomeAfterLogin'
import HomeBeforeLogin from './HomeBeforeLogin'
import useMixpanel from '../../Hooks/useMixpanel'

const index = ({
  upcomingLiveStreams,
  upcomingLiveClasses,
  upcomingMasterClass,
  upcomingIvyLeague,
  upcomingOxford,
  recordedIvyLeague,
  recordedOxbridge,
  addToCalendar,
}) => {
  const { asPath } = useRouter()

  const auth = useSelector((state) => state.auth)
  // useMixpanel(auth, asPath === '/collegepass-plus' ? 'view_plus_page_web' : 'view_home_page_web')
  useMixpanel(auth, asPath === '/collegepass-plus' ? 'view_plus_page_web' : 'view_home')
  console.log('Home page authentication', auth)
  // useEffect(() => {
  //   if (auth.isAuthenticated === true && auth.loading === false && auth.expiry_date !== undefined && auth.userDetails.EMAIL) {
  //     console.log("I'm authenticated")
  //   } else if (auth.isAuthenticated === false && auth.loading === false) {
  //     console.log("I'm not authenticated")
  //   }
  // }, [auth])
  if (auth.isAuthenticated && auth?.userDetails?.emailVerified === 1) {
    return (
      <HomeAfterLogin
        upcomingLiveStreams={upcomingLiveStreams}
        upcomingLiveClasses={upcomingLiveClasses}
        upcomingMasterClass={upcomingMasterClass}
        upcomingIvyLeague={upcomingIvyLeague}
        upcomingOxford={upcomingOxford}
        recordedIvyLeague={recordedIvyLeague}
        recordedOxbridge={recordedOxbridge}
        addToCalendar={addToCalendar}
      />
    )
  } else {
    return (
      <HomeBeforeLogin
      // upcomingLiveStreams={upcomingLiveStreams}
      // upcomingLiveClasses={upcomingLiveClasses}
      // upcomingMasterClass={upcomingMasterClass}
      // upcomingIvyLeague={upcomingIvyLeague}
      // upcomingOxford={upcomingOxford}
      // recordedIvyLeague={recordedIvyLeague}
      // recordedOxbridge={recordedOxbridge}
      />
    )
  }
}

export default index
